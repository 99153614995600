(function () {
	'use strict';

	angular
		.module('app')
		.controller('OnboardingCtrl', [
			'$scope',
			'$rootScope',
			'$location',
			'$translate',
			'utilities',
			'onboarding',
			OnboardingCtrl,
		]);

	function OnboardingCtrl(
		$scope,
		$rootScope,
		$location,
		$translate,
		utilities,
		onboarding
	) {
		//Data passed to controller from popover can be accessed from $scope.onboardingData
		var type = $scope.onboardingData.type;

		$scope.authorize = authorize;
		$scope.close = close;
		$translate(['day', 'days']).then(function (translated) {
			var dayPlural = translated;
			var dayLabel = dayPlural.day;
			var dayString = $scope.onboardingData.expires + ' ' + dayLabel;

			$scope.expiresDays = {days: dayString};
		});

		if (type === 'google') {
			$scope.typeString = {type: 'Google'};
		} else if (type === 'office365') {
			$scope.typeString = {type: 'Microsoft 365'};
		}

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};

		function authorize(e) {
			close(e);

			if (
				$scope.onboardingData &&
				$scope.onboardingData.sourceTemplate &&
				$scope.onboardingData.sourceTemplate.authFunction
			) {
				$scope.onboardingData.sourceTemplate.authFunction();
			}
		}

		function close(e) {
			//Stop propagation so we don't close popovers behind the modal when clicking close
			if (e) {
				e.stopPropagation();
			}
			$scope.popover.config.show = false;
		}
	}
})();
